import * as React from "react"
import Join from "../roaster/join"
import Hero from "../solutions/hero"
import Business from "../solutions/business"
import TeamWork from "../roaster/teamwork"
import TraceabilitySteps from "./traceabilitySteps"

import trace1 from "../../assets/images/icons/trace1.svg"
import trace2 from "../../assets/images/icons/trace2.svg"
import trace3 from "../../assets/images/icons/trace3.svg"
import trace4 from "../../assets/images/icons/trace4.svg"

const Consumer = ({ pageContext: { translate } }) => {
  const dataTeam = [
    {
      icon: trace1,
      description: translate.t?.program_mgmt,
    },
    {
      icon: trace2,
      description: translate.t?.trans_access_data,
    },
    {
      icon: trace3,
      description: translate.t?.validation_sustainable_practices,
    },
    {
      icon: trace4,
      description: translate.t?.trust_and_confidence,
    },
  ]

  return (
    <>
      {
        <div className="traceability_page">
          <div className="solution_page1">
            <Hero
              pageContext={{ translate }}
              title={translate.t?.no_t_wo_t}
              description={translate.t?.harness_crop_to_cup_traceability}
              btntext={translate.t?.book_a_demo}
              btnLink=""
              btntext2={translate.t?.our_sustainability_practices}
              btnLink2={`${
                translate.currentLang ? "/" + translate.currentLang + "/" : "/"
              }sustainability/`}
              model="contact"
            />
          </div>
          <div className="roaster_page">
            <TeamWork
              pageContext={{ translate }}
              heading={translate.t?.how_traceability_helps}
              description={translate.t?.ete_frameworks}
              btnText=""
              btnLink=""
              data={dataTeam}
            />
          </div>
          <div className="solution_page">
            <Business
              btnText=""
              description="- Niklas Löfberg"
              secondDescription={`, "CEO at Era of We"`}
              btnlink=""
              title={translate.t?.our_purpose_is_to_make_coffee_valued_by_its_sustainability}
            />
          </div>
          <TraceabilitySteps
            pageContext={{ translate }}
            heading={translate.t?.transform_and_showcase_your_traceability_efficiently}
            description={translate.t?.eraofwe_informative_meaningful_exp}
          />
          <div className="joiner-second">
            <Join pageContext={{ translate }} />
          </div>
        </div>
      }
    </>
  )
}

export default Consumer
