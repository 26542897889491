import * as React from "react"
import { Link } from "gatsby"
import Whitepaper from "../models/whitepaper"
const Hero = ({
  pagename,
  title,
  description,
  btntext,
  btnLink,
  img,
  btntext2,
  btnLink2,
  model,
  pageContext: { translate },
}) => {
  return (
    <>
      {
        <div className="hero">
          <div className="content">
            <div className="information">
              {pagename && <h2>{pagename}</h2>}
              <h1>{title}</h1>
              <p>{description}</p>
              <div className="flex-btn">
                {btntext &&
                  (model === "download" ? (
                    <Whitepaper
                      pageContext={{ translate }}
                      btnText={btntext}
                      styler="whitebtn"
                      title={translate.t?.download_the_whitepaper}
                      description={translate.t?.get_more_insights}
                      type="download"
                      submitBtnText={translate.t?.download_now}
                    />
                  ) : model === "contact" ? (
                    <Whitepaper
                      pageContext={{ translate }}
                      btnText={btntext}
                      styler={"greenbtn"}
                      title={translate.t?.contact_form}
                      description={translate.t?.contact_form_description}
                      type="contact"
                      submitBtnText={translate.t?.submit}
                    />
                  ) : (
                    <Link to={btnLink}>
                      <button className="btndefault whitebtn">{btntext}</button>
                    </Link>
                  ))}
                <Link to={btnLink2}>
                  <button className="btndefault whitebtn">{btntext2}</button>
                </Link>
              </div>
            </div>
            {img && <img src={img} alt="Solutions Hero" />}
          </div>
        </div>
      }
    </>
  )
}

export default Hero
