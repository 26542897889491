import * as React from "react";
import Layout from "../components/layout/layout";
import SEO from "../components/layout/seo";
import TraceabilityPage from "../components/traceability";
import languages from "../utils/enums/languages";

const Hospitality = ({ location, pageContext: { translate } }) => (
  <Layout pageContext={{ translate }} secondary path="eraofwe" location={location} SEO={({ data }) =>
    <SEO
      title={data.t?.no_t_wo_t}
      description={data.t?.harness_crop_to_cup_traceability}
      keywords={data.t?.keywords_traceability}
      languages={[...languages.map((l) => {
        return {
          hrefLang: l || 'en',
          href: `${process.env.GATSBY_SITE_URL}${l ? '/' + l + '/' : '/'}traceability/`
        }
      }), {
        hrefLang: 'x-default',
        href: `${process.env.GATSBY_SITE_URL}/traceability/`
      }]}
    />
  }>
    <>
      {
        (
          <>
            <TraceabilityPage pageContext={{ translate }} />
          </>
        )
      }
    </>
  </Layout>
)

export default Hospitality
