import * as React from "react"
import step1 from "../../assets/images/step1.jpg"
import step2 from "../../assets/images/step2.jpg"
import step3 from "../../assets/images/step3.jpg"
import step4 from "../../assets/images/step4.jpg"
const TraceabilitySteps = ({
  heading,
  description,
  pageContext: { translate },
}) => {
  return (
    <>
      {
        <div className="steps-wrapper">
          <div className="content">
            <div className="headings">
              <h2 className="heading-h2 semi text-darker">{heading}</h2>
              {description && (
                <p className="paragraph-p1  text-darker">{description}</p>
              )}
            </div>
            <div className="step-list">
              <div className="d-flex flex-column flex-md-row-reverse justify-content-center step-box">
                <img src={step1} alt="step1"></img>
                <div className="flex-1 d-flex text-wrapper">
                  <div className="step-number">1</div>
                  <div className="step-content">
                    <p className="step-title">
                      {translate.t?.integrate_your_trading_and_quality_data}
                    </p>
                    <p className="step-description">
                      {translate.t?.merge_your_erp_data_with_the_era_of_we_platform}
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row justify-content-center step-box">
                <div>
                <img className="my-5" src={step2} alt="step2"></img>

                </div>
                <div className="flex-1 d-flex text-wrapper">
                  <div className="step-number">2</div>
                  <div className="step-content">
                    <p className="step-title">
  
                      <span>{translate.t?.create_customised}</span>{" "}
                      <span className="digital-product-color">
                        {translate.t?.digital_product_passports}
                      </span>
                      <span>{translate.t?.web_apps_and_codes}</span>
                    </p>
                    <p className="step-description">
                      {translate.t?.trace_history_f2c}
                    </p>
                  </div>
                </div>
              </div>
              <div className="step-box">
                <img src={step3} alt="step3"></img>
                <div className="flex-1 d-flex text-wrapper">
                  <div className="step-content">
                    <p className="step-title">
                      {translate.t?.channel_your_traceability}
                    </p>
                    <p className="step-description">
                      {translate.t?.leave_reviews_connect}
                    </p>
                  </div>
                  <div className="step-number">3</div>
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row-reverse justify-content-center step-box">
                <img src={step4} alt="step4"></img>
                <div className="flex-1 d-flex text-wrapper">
                  <div className="step-number">4</div>
                  <div className="step-content">
                    <p className="step-title">
                      {translate.t?.estates_informed_about_you}
                    </p>
                    <p className="step-description">
                      {translate.t?.producers_feel_connected}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default TraceabilitySteps
